import css from './sass/style.scss';

require('./js/polyfills.js');
require('./js/init.js');
require('./js/carousels.js');
require('./js/helpers.js');
require('./js/ismobile.js');
require('./js/inview.js');
require('./js/map.js');
require('./js/nav.js');

require("./img/offer/01.jpg");
require("./img/offer/02.jpg");
require("./img/offer/03.jpg");
require("./img/offer/04.jpg");
require("./img/offer/05.jpg");
require("./img/offer/06.jpg");
require("./img/offer/07.jpg");
require("./img/offer/08.jpg");
require("./img/offer/09.jpg");
require("./img/offer/10.jpg");
require("./img/offer/11.jpg");
require("./img/offer/12.jpg");

require("./img/offer/01@2x.jpg");
require("./img/offer/02@2x.jpg");
require("./img/offer/03@2x.jpg");
require("./img/offer/04@2x.jpg");
require("./img/offer/05@2x.jpg");
require("./img/offer/06@2x.jpg");
require("./img/offer/07@2x.jpg");
require("./img/offer/08@2x.jpg");
require("./img/offer/09@2x.jpg");
require("./img/offer/10@2x.jpg");
require("./img/offer/11@2x.jpg");
require("./img/offer/12@2x.jpg");

require("./img/offer/01b.jpg");
require("./img/offer/02b.jpg");
require("./img/offer/03b.jpg");
require("./img/offer/04b.jpg");
require("./img/offer/05b.jpg");
require("./img/offer/06b.jpg");
require("./img/offer/07b.jpg");
require("./img/offer/08b.jpg");
require("./img/offer/09b.jpg");
require("./img/offer/10b.jpg");
require("./img/offer/11b.jpg");
require("./img/offer/12b.jpg");

require("./img/offer/01b@2x.jpg");
require("./img/offer/02b@2x.jpg");
require("./img/offer/03b@2x.jpg");
require("./img/offer/04b@2x.jpg");
require("./img/offer/05b@2x.jpg");
require("./img/offer/06b@2x.jpg");
require("./img/offer/07b@2x.jpg");
require("./img/offer/08b@2x.jpg");
require("./img/offer/09b@2x.jpg");
require("./img/offer/10b@2x.jpg");
require("./img/offer/11b@2x.jpg");
require("./img/offer/12b@2x.jpg");
