import Glide from '@glidejs/glide';
import css from '../../node_modules/@glidejs/glide/src/assets/sass/glide.core.scss';

document.addEventListener('DOMContentLoaded',function() {

    const home = document.getElementById('homeCarousel'),
          products = document.getElementById('productsCarousel'),
          partners = document.getElementById('partnersCarousel');
   
    const homeCarousel = function() {
    
        const glide = new Glide(home, {
            type: 'carousel',
            animationDuration: 600,
            autoplay: false,
            hoverpause: true,
            gap: 0
        })
        
        setTimeout(function() {
            glide.mount();
        }, 100)
    };
    
    const partnersCarousel = function() {
    
        const glide = new Glide(partners, {
            type: 'carousel',
            animationDuration: 600,
            autoplay: 4500,
            hoverpause: true,
            gap: 0,
            perView: 7,
            breakpoints: {
                640: {
                    perView: 3
                },
                
                1024: {
                    perView: 5
                }
            }
        })
        
        setTimeout(function() {
            glide.mount();
        }, 100)
    };
    
    const productsCarousel = function() {
    
        const glide = new Glide(products, {
            type: 'carousel',
            animationDuration: 600,
            autoplay: 4500,
            hoverpause: true,
            gap: 0,
            perView: 7,
            breakpoints: {
                640: {
                    perView: 3
                },
                
                1024: {
                    perView: 5
                }
            }
        })
        
        setTimeout(function() {
            glide.mount();
        }, 100)
    };

    home ? homeCarousel() : false;
    partners ? partnersCarousel() : false;
    products ? productsCarousel() : false;

}, false)