const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

document.addEventListener('DOMContentLoaded',function() {

    const el = document.getElementsByClassName('js-nav')[0],
          nav = document.getElementsByClassName('js-children'),
          menu = document.getElementsByClassName('js-menu')[0],
          hamburger = document.getElementsByClassName('js-hamburger')[0],
          parent = el.getElementsByTagName('li');
          

    const init = function() {

        let ww = 0, status = false;

        const checkHeight = function() {
            
            let wh = window.innerHeight,
                oh = menu.clientHeight;
            
            // if height of obj is bigger than window 
                        
            if (oh + 180 > wh) {
                if (status === false) {
                    el.classList.add('is-block');
                    status = true;
                }
                
            } else {
                if (status = true) {
                    el.classList.remove('is-block');
                    status = false;
                }
            }            
        }

        const checkWindowWidth = function() {
            ww = window.innerWidth;

            if (ww > 1200) {

                hideMenu();
            }
        }
        
        const hideMenu = function() {

            enableBodyScroll(el);
            el.classList.remove('is-visible');
            hamburger.classList.remove('is-active');

            for (let i = 0; i < nav.length; i ++) {
                nav[i].classList.remove('is-active');
            }
            
            let parent = el.getElementsByClassName('has-children');
            
            for (let i = 0; i < parent.length; i ++) {
                parent[i].classList.remove('is-active');
            }
            
            window.removeEventListener('resize', checkHeight);
        };

        const showMenu = function(e) {
        
            if (e.currentTarget.classList.contains('is-active')) {
            
                hideMenu();            
            
            } else {
            
                disableBodyScroll(el);
                el.classList.add('is-visible');
                hamburger.classList.add('is-active');
                
                window.addEventListener('resize', checkHeight);
                checkHeight();
            }
        };

        

        window.addEventListener('resize', checkWindowWidth);

        checkWindowWidth();

        hamburger.addEventListener('click', showMenu);


        const parent = menu.getElementsByTagName('li');

        const submenu = function(e) {
        
            if (ww <= 1200) {
                let item = e.currentTarget;
               
                e.stopPropagation();
                
                if (item.classList.contains('has-children')) {
                    if (item.classList.contains('is-active')) {
                        item.classList.remove('is-active');
                    } else {
                        item.classList.add('is-active');
                    }

                    checkHeight();

                } else {
                    let url = item.getElementsByTagName('a')[0].getAttribute('href');
                    window.open(url, '_self');
                    hideMenu();
                }
                
                

                e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
            }
        }


        for (let j = 0; j < parent.length; j++) {
            parent[j].addEventListener('click', submenu);
        }


        // Hide menu on ESC

        document.addEventListener('keydown', function(evt) {
            // evt = evt || window.event;
            var isEscape = false;
            if ("key" in evt) {
                isEscape = (evt.key == "Escape" || evt.key == "Esc");
            } else {
                isEscape = (evt.keyCode == 27);
            }
            if (isEscape) {
                hideMenu();
            }
        });

    };

    el ? init() : false;

}, false);